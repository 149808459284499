import React, { useLayoutEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import KontaktFormDefault from "../components/kontakt/kontaktFormDefault"
import Headline from "../components/headline"
import * as ffsStyles from "../components/sectionWrap/sectionWrap.module.css"
import SectionWrap from "../components/sectionWrap"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import CTAKontaktmodul from "../components/ctaKontaktmodul"

const Kontakt = () => {

  const data = useStaticQuery(graphql`
    query {
        ogimage: file(relativePath: { eq: "images/kontakt-og.jpg" }) {
            childImageSharp {
                fixed(height: 400) {
                    src
                    height
                    width
                }
            }
        }
    }
  `)    

  // reset scroll trigger damit seite nicht irgendwo hinspringt
  useLayoutEffect(() => {
    setTimeout(() => {
      ScrollTrigger.refresh(true)
    }, 250);
  }, []);

  return (
    <Layout menueDistance>

<SEO
        title="Kontakt"
        description="Hallo sagen, Projekt starten, online bewerben. Wir sind nur eine E-Mail entfernt und freuen uns auf Ihre/Deine Nachricht."
        image={data?.ogimage?.childImageSharp?.fixed}
      />  


      {/* header */}
      <Container className="minheight50vh d-flex flex-column justify-content-center pb-5 pb-md-0">
        <Row style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          height: "100%"
        }}>
          <Col md={7}>
            <Headline
              titel={<>Hallo sagen, Projekt starten, online bewerben.</>}
              showCta={false}
              skyhead={false}
              nomargin={true}
              text={<>Wir sind nur eine E-Mail entfernt und freuen uns auf Ihre/Deine <a href="mailto:dialog@Qmarketing.de">Nachricht. </a><br /></>}
            />  
          </Col>
          <Col md={5}>
            <Headline
              titel=""
              showCta={false}
              skyhead={false}
              nomargin={true}
              text={<div className="qliste liniert">
                <ul>
                  <li>Düsseldorfer Straße 193 <br />45481 Mülheim an der Ruhr <a href="https://maps.google.com/maps/dir//Q:marketing+Aktiengesellschaft+D%C3%BCsseldorfer+Str.+193+45481+M%C3%BClheim+an+der+Ruhr/@51.4099073,6.8686461,17z/data=!4m5!4m4!1m0!1m2!1m1!1s0x47b8c139f556619f:0xfef495f32fbe86d4" target="_blank">(Anfahrt)</a></li>
                  <li>E-Mail: <a href="mailto:dialog@Qmarketing.de">dialog@Qmarketing.de</a></li>
                  <li>Telefon: <a href="tel:+4920830150">0208 30 15-0</a></li>
                  <li>Social: <a href="https://www.instagram.com/qmarketing.de" target="_blank">Instagram</a><span style={{ color: "#cccccc" }}>|<a href="https://www.facebook.com/Qmarketing.de/" target="_blank">Facebook</a></span> </li>
                </ul>
              </div>}
            />
          </Col>
        </Row>
      </Container>

      {/* <Chatbot /> */}

      {/* swiper voll breite */}
      {/* <SectionWrap bgCol={"#ffffff"} className="fullHeight" pin="false">
        <div className={`row ${ffsStyles.vcenterRowContent}`}>
          <div className={`col-12`}>
            <SwiperDefault>

              <SwiperSlide>
                <StaticImage src="../images/mountain.jpg" alt="ein bild" placeholder="blurred" className="fullHeight" />
              </SwiperSlide>

            </SwiperDefault>
          </div>
        </div>
      </SectionWrap> */}

      {/* karte */}
      {/* https://studio.mapbox.com/styles/qmarketingmh/ckz9ucvsd002r14tad4wmvk9v/edit/#16.12/51.410629/6.871472 */}
      {/* <iframe width='100%' height='100%' src="https://api.mapbox.com/styles/v1/qmarketingmh/ckz9ucvsd002r14tad4wmvk9v.html?title=false&access_token=pk.eyJ1IjoicW1hcmtldGluZ21oIiwiYSI6ImNrejl0bmtwbTF2bzUycXJ4aGwxNXQ5cnAifQ.adGsHWrdXzu8iwiGncC30A&zoomwheel=false#17.11/51.409851/6.868995/13.6/68" title="Monochrome" style={{ border: "none" }}></iframe> */}
      <div className="container-fluid">
        <SectionWrap bgCol={"#ffffff"} pin={false}>
          <div className={`row ${ffsStyles.vcenterRowContent}`}>
            <div className={`col height80`}>
              <iframe className="minheight80vh" width='100%' height='100%' src="https://api.mapbox.com/styles/v1/qmarketingmh/ckz9ucvsd002r14tad4wmvk9v.html?title=false&access_token=pk.eyJ1IjoicW1hcmtldGluZ21oIiwiYSI6ImNrejl0bmtwbTF2bzUycXJ4aGwxNXQ5cnAifQ.adGsHWrdXzu8iwiGncC30A&zoomwheel=false#17.27/51.410047/6.869259/-7.2/69" title="Monochrome" style={{ border: "none" }}></iframe>
            </div>
          </div>
        </SectionWrap>
      </div>

      <KontaktFormDefault title="" backgroundclass="whiteback" />          

      {/* 
        next
      */}
      {/* <WhatsNext text="Projekte &rarr;" link="/projekte" /> */}

    </Layout>
  )
}

export default Kontakt