import React from "react"
import { Button, Modal } from "react-bootstrap"
import KontaktFormDefault from "../kontakt/kontaktFormDefault"

const CTAKontaktmodul = props => {
  const [show, setShow] = React.useState(false)

  const handleShowToggle = () => {
    setShow(value => !value)
  }

  return (
    <>
      <button
        type="submit"
        className="q-btn q-btn-secondary d-inline mt-2 mt-md-0 w-auto"
        onClick={handleShowToggle}
        style={{ width: "100%" }}
      >
        Kontaktformular
      </button>
      <Modal show={show} size="xl" onHide={handleShowToggle}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <KontaktFormDefault title="" backgroundclass="whiteback" />{" "}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default CTAKontaktmodul
